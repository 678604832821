import React from "react"
import { Modal, Table } from "antd"
import { useIntl } from "gatsby-plugin-intl"

const ModalTable = ({
  onHideModal,
  tableColumns,
  data,
  title,
  modalVisible,
}) => {
  const intl = useIntl()

  const hideModal = () => {
    if (typeof onHideModal === "function") {
      onHideModal()
    }
  }

  const renderTable = () => {
    return (
      <div>
        <Table
          className="modal-table__table"
          size="small"
          rowKey={item => item.name}
          pagination={false}
          columns={tableColumns}
          dataSource={data}
          locale={{
            filterTitle: intl.formatMessage({ id: "filters" }),
            filterConfirm: intl.formatMessage({ id: "apply" }),
            filterReset: intl.formatMessage({ id: "reset" }),
            emptyText: intl.formatMessage({ id: "no-data" }),
          }}
        />
      </div>
    )
  }

  return (
    <Modal
      className="modal-table"
      title={title}
      visible={modalVisible}
      style={{ top: 20 }}
      onOk={hideModal}
      onCancel={hideModal}
      footer={null}
    >
      {renderTable()}
    </Modal>
  )
}

export default ModalTable
