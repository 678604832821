import React, { useEffect } from "react"
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Row,
  Table,
  Tooltip,
} from "antd"

import LocalizedAniLink from "../../components/localizedAniLink"
import withAuth from "../../components/withAuthHoc"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import { navigate, useIntl } from "gatsby-plugin-intl"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import ModalTable from "../../components/modalTable"
import useStateObject from "../../hooks/useStateObject"
import indexUtils from "../../utils/dictionary/index-utils"

const DictionaryList = () => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    data: null,
    showData: [],
    modalVisible: false,
    selectedDictionaryToViewDetail: null,
    selectedNode: {},
    loading: false,
  })

  useEffect(() => {
    setState({ loading: true })
    indexUtils
      .grabData(`${process.env.GATSBY_CONF_API_URL}/dictionaries/`)
      .then(response => indexUtils.callbackTable({ response, setState }))
      .catch(message.error)
  }, [])

  return (
    <>
      <Template selected={["dictionary", "dictionary-index"]}>
        <CustomBreadcrumb
          crumbs={[
            intl.formatMessage({
              id: "menu-dictionary-management",
            }),
          ]}
        />
        <Row>
          <Col className="intro">
            <h1>{intl.formatMessage({ id: "menu-dictionary-list" })}</h1>
          </Col>
        </Row>
        <div className="content">
          <Row type="flex" justify="center" align="top">
            <Col span={12}>
              <Form.Item>
                <Input
                  style={{ height: "40px" }}
                  required
                  name="dictionary"
                  onChange={indexUtils.handleFilterDict({ state, setState })}
                  placeholder={intl.formatMessage({
                    id: "dictionary-filter-placeholder-text",
                  })}
                  suffix={
                    <Tooltip
                      title={intl.formatMessage({
                        id: "dictionary-tooltip-final-format",
                      })}
                    >
                      <Icon
                        type="info-circle"
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                  disabled={state.loading}
                />
              </Form.Item>
            </Col>
            <Col span={12} align="right">
              {checkPermissions(["dictionary:write"]) ? (
                <LocalizedAniLink
                  to="/dictionary/form"
                  state={{
                    dictionary: null,
                    isNewVersion: false,
                    versions: null,
                  }}
                >
                  <Button type="primary" style={{ height: "40px" }}>
                    {intl.formatMessage({
                      id: "menu-dictionary-create",
                    })}
                  </Button>
                </LocalizedAniLink>
              ) : null}
            </Col>

            <Col span={24}>
              <Table
                className="ingests-table"
                showHeader={true}
                size="small"
                rowKey="name"
                columns={indexUtils.columnsDict({ intl, setState })}
                dataSource={state.showData}
                loading={state.loading}
                pagination={{ defaultPageSize: 10 }}
                locale={{
                  filterTitle: intl.formatMessage({
                    id: "filters",
                  }),
                  filterConfirm: intl.formatMessage({
                    id: "apply",
                  }),
                  filterReset: intl.formatMessage({ id: "reset" }),
                  emptyText: intl.formatMessage({ id: "no-data" }),
                }}
                onRow={r => ({
                  onClick: () => {
                    navigate("/dictionary/detail", {
                      state: { dictionary: r },
                    })
                  },
                })}
              />
            </Col>
          </Row>
        </div>
      </Template>
      <ModalTable
        title={intl.formatMessage({
          id: "page-new-ingestion-dictionary-detail-modal-title",
        })}
        modalVisible={state.modalVisible}
        dataId={
          state.selectedDictionaryToViewDetail &&
          state.selectedDictionaryToViewDetail.id
        }
        data={
          state.selectedDictionaryToViewDetail &&
          state.selectedDictionaryToViewDetail.fields
        }
        fields={
          state.selectedDictionaryToViewDetail &&
          state.selectedDictionaryToViewDetail.fields
        }
        tableColumns={indexUtils.modalColumns({ intl })}
        onHideModal={() => setState({ modalVisible: false })}
      />
    </>
  )
}

export default withAuth(DictionaryList)
