import React from "react"
import { Button, message, Modal, Tag } from "antd"
import authFetch from "../../services/network"
import { formatIngestDate } from "../../services/ingest"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import deleteIcon from "../../assets/images/ingests/ic-delete-on.png"
import eyeIcon from "../../assets/images/ic_eye.png"

const { confirm } = Modal

const grabData = (uri, obj = null) => {
  return authFetch(uri, obj)
}

const regroupDictionaries = dictionaries => {
  const result = []
  if (dictionaries && dictionaries.length > 0) {
    const regroup = dictionaries.reduce(function(r, a) {
      r[a.name] = r[a.name] || []
      r[a.name].push(a)
      return r
    }, Object.create(null))
    Object.keys(regroup).forEach(function(key) {
      const versions = regroup[key].sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      )
      result.push({
        id: versions[0].id,
        name: key,
        description: versions[0].description,
        createdAt: versions[versions.length - 1].createdAt,
        fields: versions[versions.length - 1].fields,
        countVersions: versions.length,
        versions,
      })
    })
  }
  return result
}

const callbackTable = ({ response, setState }) => {
  setState({ loading: false })
  if (response.status !== 200) {
    throw `Looks like there was a problem. Status Code: ${response.status}`
  }

  // Examine the text in the response
  response.json().then(data => {
    // regroup and count versions
    const dictionaries = regroupDictionaries(data)
    setState({ data: dictionaries, showData: dictionaries })
  })
}

/**
 * Method to filter the dictionaries showed at table
 * @param e
 */
const handleFilterDict = ({ state, setState }) => e => {
  const value = e.currentTarget.value
  const { data } = state
  const showData = data.filter(item =>
    item.name.toLowerCase().includes(value.toLowerCase())
  )
  setState({
    data,
    showData,
  })
}

const onRowDelete = (record, intl) => {
  confirm({
    title: intl.formatMessage({ id: "modal-are-you-sure" }),
    content: intl.formatMessage({ id: "dictionary-delete-text-confirm" }),
    okText: intl.formatMessage({ id: "modal-ok" }),
    okType: "danger",
    cancelText: intl.formatMessage({ id: "modal-ko" }),
    onOk() {
      grabData(`${process.env.GATSBY_CONF_API_URL}/dictionaries/${record.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response =>
          callbackDeleteOk({ response, dictionaryId: record.id, intl })
        )
        .catch(err => callbackDeleteKo({ err, intl }))
    },
    onCancel() {
      message.info(intl.formatMessage({ id: "modal-delete-cancel" }))
    },
  })
}

const callbackDeleteOk = ({ response, dictionaryId, intl }) => {
  if (200 === response.status) {
    const data = state.data.filter(d => d.id !== dictionaryId)
    const showData = state.showData.filter(d => d.id !== dictionaryId)
    setState({ data, showData })
    message.success(intl.formatMessage({ id: "dictionary-delete-ok" }))
  } else {
    callbackDeleteKo({ err: response, intl })
  }
}

const callbackDeleteKo = ({ err, intl }) => {
  switch (err.status) {
    case 400:
      message.error(intl.formatMessage({ id: "dictionary-error-text-in-use" }))
      break
    case 404:
      message.error(
        intl.formatMessage({
          id: "dictionary-error-text-no-exist",
        })
      )
      break
    case 500:
      message.error(
        intl.formatMessage({
          id: "dictionary-error-text-server-error",
        })
      )
      break
    default:
      message.error(
        intl.formatMessage({
          id: "dictionary-error-text-unknown-error",
        })
      )
  }
}

const onDictionaryShowAllFields = ({ dictionary, setState }) => {
  if (dictionary && dictionary.fields && dictionary.fields.length > 0) {
    setState({
      selectedDictionaryToViewDetail: dictionary,
      modalVisible: true,
    })
  }
}

const columnsDict = ({ intl, setState }) => [
  {
    title: intl.formatMessage({ id: "created-at" }),
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    render: formatIngestDate,
    sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    sortDirections: ["ascend", "descend"],
    ellipsis: true,
  },
  {
    title: intl.formatMessage({ id: "name" }),
    dataIndex: "name",
    key: "name",
    width: 400,
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: intl.formatMessage({ id: "n-versions" }),
    dataIndex: "countVersions",
    key: "countVersions",
    width: 200,
    sorter: (a, b) => a.countVersions - b.countVersions,
    sortDirections: ["ascend", "descend"],
    ellipsis: true,
    align: "center",
  },
  {
    title: intl.formatMessage({ id: "fields" }),
    dataIndex: "fields",
    key: "fields",
    render: (tags, dictionary) => {
      if (tags && tags.length > 0) {
        const results = []
        const tagIndex = tags.find(tag => tag.property === "TS_INDEX")
        results.push(tagIndex)
        const otherTags = tags.filter(tag => tag.property !== "TS_INDEX")
        results.push(...otherTags.slice(0, 3))
        const viewAll = tags.length > 4
        return (
          <div>
            {results.map(tag => (
              <Tag key={tag.name}>{tag.name.toUpperCase()}</Tag>
            ))}
            {viewAll && (
              <Button
                type="link"
                aria-label="view-all"
                style={{ paddingLeft: 0 }}
                onClick={event => {
                  event.stopPropagation()
                  onDictionaryShowAllFields({ dictionary, setState })
                }}
              >
                {intl.formatMessage({ id: "view-all" })}
              </Button>
            )}
          </div>
        )
      }
      return ""
    },
  },
  {
    title: intl.formatMessage({ id: "actions" }),
    dataIndex: "",
    key: "id",
    width: 120,
    align: "center",
    render: (text, dictionary) => (
      <div>
        <button
          className="button-no-styles"
          onClick={event => {
            event.stopPropagation()
            onDictionaryShowAllFields({ dictionary, setState })
          }}
        >
          <img style={{ fontSize: "1.25rem" }} src={eyeIcon} alt="eyeIcon" />
        </button>
        {checkPermissions(["dictionary:delete"]) ? (
          <button
            className="button-no-styles"
            onClick={event => {
              event.stopPropagation()
              onRowDelete(dictionary, intl)
            }}
          >
            <img
              style={{ fontSize: "1.25rem" }}
              src={deleteIcon}
              alt="deleteIcon"
            />
          </button>
        ) : null}
      </div>
    ),
  },
]

const modalColumns = ({ intl }) => [
  {
    title: intl.formatMessage({
      id: "new-ingestion-tables-column-header-field",
    }),
    dataIndex: "name",
    key: "name",
  },
  {
    title: intl.formatMessage({
      id: "new-ingestion-tables-column-header-type",
    }),
    dataIndex: "type",
    key: "type",
  },
  {
    title: intl.formatMessage({
      id: "new-ingestion-tables-column-header-property",
    }),
    dataIndex: "property",
    key: "property",
  },
]

const indexUtils = {
  modalColumns,
  columnsDict,
  handleFilterDict,
  callbackTable,
  grabData,
}

export default indexUtils
